<template>
  <div>
    <pre v-html="format(encoded)" />
    <div class="copy" @click="copy">{{ copied ? 'скопировано' : 'копировать' }}</div>
  </div>
</template>

<script>
import { ref, computed, onUnmounted } from 'vue'

export default {
  name: 'Code',
  props: ['lang', 'code'],
  setup(props) {
    const encoded = computed(() => {
      const lines = props.code.split('\n').slice(1, -1)
      let spaces = 0
      for (const c of lines[0]) {
        if (c === ' ') {
          spaces++
        } else {
          break
        }
      }
      return lines.map((line) => line.slice(spaces)).join('\n')
    })
    let format

    if (props.lang === 'js') {
      const quote = /('.*?'|".*?"|`.*?`)/g
      const keywords = /\b(for|while|if|else|let|const|throw|new|return|true|false|break|continue)\b/g
      const signs = /(\(|\)|\[|\]|\{|\}|;)/g
      format = (s) => {
        s = s.replace(quote, '<span class="string">$1</span>')
        s = s.replace(keywords, '<span class="keyword">$1</span>')
        s = s.replace(signs, '<span class="sign">$1</span>')
        return s
      }
    } else if (props.lang === 'py') {
      const quote = /(""".*?"""|'''.*?'''|'.*?'|".*?")/g
      const keywords = /\b(for|while|if|elif|else|return|True|False|def|class(?!=)|not|in|break|continue|lambda)\b/g
      const signs = /(\(|\)|\[|\]|\{|\})/g
      format = (s) => {
        s = s.replace(quote, '<span class="string">$1</span>')
        s = s.replace(keywords, '<span class="keyword">$1</span>')
        s = s.replace(signs, '<span class="sign">$1</span>')
        return s
      }
    }
    const copied = ref(false)
    let timer = null
    const copy = () => {
      navigator.clipboard.writeText(encoded.value).then(() => {
        copied.value = true
        clearInterval(timer)
        timer = setTimeout(() => {
          copied.value = false
        }, 1000)
      })
    }
    onUnmounted(() => {
      clearInterval(timer)
    })

    return {
      encoded,
      format,
      copied,
      copy
    }
  }
}
</script>

<style scoped>
pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  border-radius: 15px;
  color: #333;
  font-size: 18px;
  overflow: auto;
  padding: 15px;
  white-space: break-spaces;
}
pre :deep(.string) {
  color: #f36d33;
}
pre :deep(.keyword) {
  color: #002bff;
}
pre :deep(.sign) {
  font-weight: bold;
  color: darkblue;
}
.copy {
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
}
.copy:hover {
  color: #666666;
}
.copy:active {
  color: green;
}
</style>
